/**
 * Site header
 */
.site-header {
  z-index: 10;
  min-height: $spacing-unit * 1.865;
  // Positioning context for the mobile navigation icon
  position: relative;
}
.site-info {
  padding: 0.5em 0;
  text-align: center;
  .site-title {
    font-family: $head-font-family;
    font-weight: normal;
    font-size: 48px;  
    line-height: 1.6em;
    a {
      color: $text-color;
      text-decoration: underline;
      text-decoration-style: wavy;
      text-decoration-color: $accent-color;
      text-decoration-thickness: 4px;
      text-underline-offset: 18px;
    }
  }
  .site-subtitle {
    max-width: 360px;
    text-align: center;
    margin: 12px auto -12px;
    font-family: $head-font-family;
    color: $grey-color;
    font-size: 18px;
    .capital {
      color: $accent-color;
    }
  }
}
.main-nav {
  background-color: $brand-color;
  padding: 2em 0;
  --mask:
    radial-gradient(67.08px at 50% 90.00px,#000 99%,#0000 101%) calc(50% - 60px) 0/120px 51% repeat-x,
    radial-gradient(67.08px at 50% -60px,#0000 99%,#000 101%) 50% 30px/120px calc(51% - 30px) repeat-x,
    radial-gradient(67.08px at 50% calc(100% - 90.00px),#000 99%,#0000 101%) calc(50% - 60px) 100%/120px 51% repeat-x,
    radial-gradient(67.08px at 50% calc(100% + 60.00px),#0000 99%,#000 101%) 50% calc(100% - 30px)/120px calc(51% - 30px) repeat-x;
  -webkit-mask: var(--mask);
          mask: var(--mask);
}
.nav-icon {
  filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(126deg) brightness(107%) contrast(101%);
  width: 20px;
  height: 20px;
  margin-right: 8px;
  &:hover {
    transition: 0.6s;
    filter: none;
  }
}
.site-nav {
  text-align: center;
  line-height: $base-line-height * $base-font-size * 2;
  .nav-links {
    padding: 0.5em;
    .page-link {
      display: inline-block;
      border-radius: 16px;
      color: white;
      font-weight: bold;
      background-color: $accent-color;
      line-height: $base-line-height;
      padding: 0.5em 1em;
      font-size: 0.9em;
      text-transform: uppercase;
      text-decoration: none;
      &.current {
        color: $text-color;
        background-color: $highlight-color;
        .nav-icon {
          filter: none;
        }
      }
      &:hover {
        color: $text-color;
        background-color: $hover-color;
        transition: 0.6s;
        .nav-icon {
          filter: none;
        }
      }
      // Gaps between nav items, but not on the last one
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}

/* Additional nav bar for sections */
.nav-bar {
  z-index: 0;
  background-color: $background-highlight;
  margin-top: -50px;
  padding: 1em 0 2.25em;
  --mask:
    radial-gradient(67.08px at 50% calc(100% - 90.00px),#000 99%,#0000 101%) calc(50% - 60px) 0/120px 100%,
    radial-gradient(67.08px at 50% calc(100% + 60.00px),#0000 99%,#000 101%) 50% calc(100% - 30px)/120px 100% repeat-x;
  -webkit-mask: var(--mask);
          mask: var(--mask);
}
.section-nav {
  text-align: center;
  line-height: $base-line-height * $base-font-size * 1.5;
  .nav-links {
    padding: 0.5em 0;
    .page-link {
      display: inline-block;
      color: $url-color;
      font-weight: bold;
      line-height: $base-line-height;
      padding: 0.5em 1em;
      font-size: 0.8em;
      text-transform: uppercase;
      text-decoration: underline;
      text-decoration-style: solid;
      text-decoration-color: $url-color;
      text-decoration-thickness: 4px;
      text-underline-offset: 12px;
      &.current {
        color: $accent-color;
        text-decoration-style: wavy;
        text-decoration-color: $accent-color;
      }
      &:hover {
        color: #275E9E;
        text-decoration-style: wavy;
        text-decoration-color: $hover-color;
        transition: 0.6s;
      }
      // Gaps between nav items, but not on the last one
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}


/**
 * Site footer
 */
.site-footer {
  background-color: $accent-color;
  padding: $spacing-unit;
  padding: 100px 0 0;
  --wave-top:
    radial-gradient(156.52px at 50% 210.00px,#000 99%,#0000 101%) calc(50% - 140px) 0/280px 100%,
    radial-gradient(156.52px at 50% -140px,#0000 99%,#000 101%) 50% 70px/280px 100% repeat-x;
  -webkit-mask: var(--wave-top);
          mask: var(--wave-top);
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: $spacing-unit / 2;
}
.author-avatar {
  float: left; 
  margin: 0 12px 0 0;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  border: 3px solid $accent-color;
}
.footer-col-1 h3 {
  margin-bottom: 0;
}

.footer-list {
  list-style: none;
  margin-left: 6px;
  line-height: 2;
}

.footer-col-wrapper {
  padding: 0 $spacing-unit;
  @include relative-font-size(0.85);
  color: $text-color;
  @extend %clearfix;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-gap: $spacing-unit;
  justify-content: center;
  margin: $spacing-unit auto;
  max-width: $layout-width;
}

.footer-col {
  background-color: white;
  border-radius: 52px;
  padding: 2.5em;
  h3 {
    font-weight: normal;
  }
  p:last-child {
    margin-bottom: 0;
  }
}

@include media-query(1210px) {
  .footer-col-wrapper {
    grid-template-columns: 2fr 1fr;
    font-size: $palm-font-size;
  }
}

@include media-query($on-laptop) {
  .footer-col-wrapper {
    display: flex; 
    flex-direction: column;
    padding: 0 12px;
    .footer-col {
      border-radius: 32px;
      padding: 2em 1.5em;
    }
  }
}

/**
 * Home page
 */
.home {
  width: 100%;
  
}
.home-col-wrapper {
  padding: 0 $spacing-unit;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: $spacing-unit;
  justify-content: center;
  margin: $spacing-unit auto;
  max-width: $layout-width;
}
.home-box {
  background-color: white;
  border-radius: 73px;
  padding: 3em;
  margin-bottom: $spacing-unit;
}
.home-more {
  margin-top: 60px;
  text-align: right;
  font-size: .9em;
  &.no-content {
    text-align: center;
    font-size: 20px;
    display: inline-block;
    margin-top: 10px;
    &:not(:last-child) {
      margin-right: 10px;
      margin-bottom: 30px;
    }
  }
  .read-more-icon {
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(126deg) brightness(107%) contrast(101%);
  }
  .read-more-link {
    background-color: $accent-color;
    border-radius: 16px;
    padding: 0.5em 1em;
    color: white;
    &:hover {
      color: $text-color;
      background-color: $hover-color;
      transition: 0.6s;
      .read-more-icon {
        filter: none;
      }
    }
    &.external {
      background-color: $brand-color;
      &:hover {
        background-color: $highlight-color;
      }
    }
  }
}
.item-title {
  font-weight: bold;
  margin-bottom: -($spacing-unit / 2);
  text-wrap: balance;
  a {
    text-decoration: none;
  }
  img {
    float: right;
    width: 120px;
    height: 80px;
    border-radius: 6px;
    margin-top: 10px;
    margin-left: 8px;
    border: 2px solid $url-color;
    object-fit: cover;
  }
}
.home-list {
  margin-left: 0;
  list-style: none;
  li {
    list-style: none;
    margin-bottom: $spacing-unit;

  }
}
.video-disclaimer {
  font-style: italic;
  font-size: $video-text;
  color: $grey-color;
  margin-bottom: 1em;
}
.featured-videos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: $spacing-unit * 2;
  justify-content: center;
  .video-card {
    padding: 0;
  }
}

@include media-query(1210px) {
  .home-col-wrapper {
    margin: ($spacing-unit / 2) 0;
    display: flex; 
    flex-direction: column;
    grid-gap: 0;
  }
}
@include media-query($on-laptop) {
  .home-col-wrapper {
    padding: 0 ($spacing-unit / 2);
  }
  .home-box {
    border-radius: 42px;
    padding: 2em;
  }
  .featured-videos {
    max-width: 280px;
    display: flex; 
    flex-direction: column;
  }
  .home-more {
    text-align: center;
    &.no-content {
      display: block;
      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
    .read-more-link {
      font-size: 18px;
      display: block;
    }
    .read-more-icon {
      width: 18px;
      height: 18px;
    }
  }
}


/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
  flex: 1;
  h2:not(:first-of-type) {
    margin-top: $spacing-unit * 2;
  }
}

.page-heading {
  @include relative-font-size(2);
}


/**
 * Posts list
 */
.posts {
  padding: 0 $spacing-unit;
}
.posts-page-info {
  max-width: $content-width;
  margin: 0 auto;
  h1 {
    margin-bottom: $spacing-unit;
  }
}
.post-list {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 400px));
  grid-gap: $spacing-unit;
  justify-content: center;
  .post-card {
    background-color: white;
    border: 3px solid $url-color;
    padding: 1em;
    border-radius: 12px;
    &.external {
      border: 2px dashed $grey-color-light;
      background: rgba(255,255,255,0.75);
    }
    &.highlighted {
      border: 3px solid $accent-color;
    }
    &:hover {
      transition: 0.3s;
      border-color: $highlight-color;
      background-color: #fffcec;
    }
  }
}
.post-card-content {
  a {
    text-decoration: none;
  }
  h1 {
    font-family: $base-font-family;
    font-size: $base-font-size;
    line-height: 125%; /* Same as .post-title */
    text-decoration: none;
  }
  .post-thumbnail {
    float: right;
    width: 160px;
    height: 120px;
    border-radius: 6px;
    margin-top: 10px;
    margin-left: 8px;
    border: 2px solid $url-color;
    object-fit: cover;
  }
  p {
    font-size: $video-text;
    color: $text-color;
    margin-bottom: $spacing-unit / 2;
  }
}
.post-more {
  margin: 8px 0;
  .read-more-link {
    font-size: 20px;
    .external-icon {
      filter: invert(68%) sepia(16%) saturate(4658%) hue-rotate(79deg) brightness(94%) contrast(70%);
      width: 18px;
      height: 18px;
      margin-left: 8px;
    }
    &:hover {
      transition: 0.6s;
      .external-icon {
        filter: invert(36%) sepia(45%) saturate(2793%) hue-rotate(285deg) brightness(75%) contrast(95%);
      }
    }
  }
}

.post-date {
  font-size: $small-font-size;
  color: $grey-color;
  margin-bottom: 1em;
}

@mixin custom-before($background-image, $width: $video-text, $height: $video-text, $padding-right: 0) {
  &::before {
    content: "";
    display: inline-block;
    width: $width;
    height: $height;
    background-image: $background-image;
    background-repeat: no-repeat;
    background-size: contain;
    padding-right: $padding-right;
  }
}

.post-meta {
  color: $grey-color;
  font-size: $video-text;
  margin-top: 1em;
  
  @include custom-before(url(/assets/img/ialja-mark.svg));

  .meta-category,
  .post-categories,
  .post-tags {
    text-transform: uppercase;
    a {
      text-decoration: none;
      &:not(:last-child)::after {
        content: ",";
      }
    }
  }
  .post-categories,
  .post-tags {
    display: inline;
  }
  .post-tags {
    text-transform: none;
    font-variant-caps: small-caps;
  }
}
.post-meta.external {
  @include custom-before(url(/assets/img/external.svg), 12px, 12px);
}
.post-meta.tags {
  margin-top: 0;
  @include custom-before(url(/assets/img/hashtag.svg), 12px, 12px);
  a {
    text-decoration: none;
    font-variant-caps: small-caps;
    &:not(:last-child)::after {
      content: ",";
    }
  }
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}

.autopage-title {
  margin-top: 0.5em;
  margin-bottom: 1.5em;
  color: $accent-color;
  font-family: $head-font-family;
  font-size: 1.2em;
  .autopage-keyword {
    font-weight: bold;
  }
}

.read-more-icon {
  filter: invert(60%) sepia(0%) saturate(564%) hue-rotate(168deg) brightness(85%) contrast(88%);
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.read-more-link {
  font-weight: bold;
  text-decoration: none;
  &:hover {
    .read-more-icon {
        transition: 0.6s;
        filter: invert(29%) sepia(100%) saturate(1083%) hue-rotate(278deg) brightness(88%) contrast(100%);
    }
  }
  &:after {
    font-size: 1.125em;
    content: "\2192";
    line-height: 1.5;
    margin-left: 5px;
  }
}
.posts-footer {
  background-color: $background-highlight;
  margin-top: 50px;
  margin-bottom: -140px;
  padding: 5em 0;
  --mask:
    radial-gradient(67.08px at 50% calc(0% + 90.00px), #000 99%, #0000 101%) calc(50% - 60px) 0/120px 100%,
    radial-gradient(67.08px at 50% calc(0% - 60.00px), #0000 99%, #000 101%) 50% calc(0% + 30px)/120px 100% repeat-x;
  -webkit-mask: var(--mask);
          mask: var(--mask);
}
.posts-tags {
  max-width: 1400px;
  margin: 0 auto;
  text-align: center;
}
.posts-tags h3 {
  color: $grey-color;
  font-size: $video-text;
  display: inline-block;
  padding-right: 4px;
  font-family: $base-font-family;
  margin: 0;
}
.posts-tags .tag {
  display: inline-block;
  font-variant-caps: small-caps;
  @include custom-before(url(/assets/img/hashtag.svg), 8px, 8px, 2px);
  &.small {
    font-size: 14px;
  }
  &.medium {
    font-size: 17px;
  }
  &.large {
    font-size: 20px;
  }
  a {
    text-decoration: none;
    padding-right: 6px;
    &.current {
      color: $accent-color;
      font-weight: bold;
    }
  }
}

@include media-query($on-laptop) {
  .post-list {
    display: flex; 
    flex-direction: column;
  }
}


/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

$title-large: $base-font-size * 1.75;
$title-small: $base-font-size * 1.25;

.post-title {
  font-family: $base-font-family;
  font-size: $title-large;
  line-height: 125%;
}

@include media-query($on-palm) {
  .post-title {
    font-size: $title-small;
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    margin-top: $spacing-unit * 2;
    @include relative-font-size(1.5);

    @include media-query($on-palm) {
      @include relative-font-size(1.25);
    }
  }

  h3 {
    margin-top: $spacing-unit;
    @include relative-font-size(1.25);

    @include media-query($on-palm) {
      @include relative-font-size(1.125);
    }
  }

  h4 {
    @include relative-font-size(1.125);

    @include media-query($on-palm) {
      @include relative-font-size(1);
    }
  }
  &::after {
    content: "";
    display: inherit;
    width: 26px;
    height: 26px;
    background-image: url(/assets/img/ialja-mark.svg);
    background-repeat: space;
    background-size: contain;
    margin: 46px auto 0;
  }
}

.post-navigation {
  font-size: 0.875em;
  margin-top: 2.5em;
  margin-bottom: 1em;
  padding: 0 $spacing-unit;
  display: block;
  width: auto;
  overflow: hidden;

  a {
    text-decoration: none;
    border-bottom: none;
    display: block;
    width: 100%;
    float: none;
    text-align: center;
    margin: 1em 0;
  }

  .prev {
    &::before {
      content: "<";
      margin-right: 6px;
    }
  }

  .next {
    &::after {
      content: ">";
      margin-left: 6px;
    }
  }
}

.footnotes p {
  margin-block-start: 0.25em;
  margin-block-end: 0.25em;
  font-size: 0.9em;
}


.post-toc {
  > ul {
    margin-left: 0px;
    > li {
      list-style: none;
      padding-bottom: 12px;
      > a {
        text-decoration-color: $accent-color;
        text-decoration-thickness: 2px;
        font-weight: bold;
      }

      > ul {
        margin-left: 12px;
        > li {
          list-style: none;
          font-size: 20px;
          &::before {
            content: '├── '; // Nested item bullet
            font-family: monospace;
            font-size: 20px;
            color: $accent-color;
          }
          &:first-child {
            padding-top: 4px;
          }
          &:last-child::before {
            content: '└── '; // Last item bullet
          }
          > a {
            text-decoration: none;
          }
        }
      }
    }
  }
}

.example-block {
  background-color: rgba($background-highlight, 0.5);
  border-left: 4px solid $accent-color;
  padding: 20px;
  font-size: 0.9em;
  border-radius: 0 12px 12px 0;
  p {
    margin-bottom: 24px;
  }
  li {
    padding-bottom: 16px;
  }
}

.disclaimer {
  margin: ($spacing-unit * 3) 0;
  padding: 1em;
  font-size: 0.9em;
  font-style: italic;
  background-color: lighten($highlight-color, 25%);
  border: 1px solid $accent-color;
  border-radius: 12px;
}

.immersive {
  margin: ($spacing-unit) 0;
  padding: 1em;
  font-size: 0.85em;
  background-color: lighten($brand-color, 45%);
  border: 2px solid $accent-color;
  border-radius: 6px;
}
.divider {
  margin: $spacing-unit auto;
  &::after {
    content: "";
    display: inherit;
    width: 26px;
    height: 26px;
    background-image: url(/assets/img/ialja-mark.svg);
    background-repeat: space;
    background-size: contain;
    margin: 46px auto 0;
  }
}


@media only screen and (min-width: 800px) {
  .post-navigation {
    a {
      width: 50%;
      float: left;
      text-align: left;
    }
    .next {
      text-align: right;
      &:only-child {
        float: right;
      }
    }
  }
}

/**
 * Videos
 */
.videos {
  padding: 0 $spacing-unit;
}
.video-page-info {
  max-width: $content-width;
  margin: 0 auto;
}
.featured {
  background-color: $highlight-color;
  padding: 60px $spacing-unit;
  --mask:
    radial-gradient(67.08px at 50% 90.00px,#000 99%,#0000 101%) calc(50% - 60px) 0/120px 51% repeat-x,
    radial-gradient(67.08px at 50% -60px,#0000 99%,#000 101%) 50% 30px/120px calc(51% - 30px) repeat-x,
    radial-gradient(67.08px at 50% calc(100% - 90.00px),#000 99%,#0000 101%) calc(50% - 60px) 100%/120px 51% repeat-x,
    radial-gradient(67.08px at 50% calc(100% + 60.00px),#0000 99%,#000 101%) 50% calc(100% - 30px)/120px calc(51% - 30px) repeat-x;
  -webkit-mask: var(--mask);
          mask: var(--mask);
}
.video-container {
  padding: 0 $spacing-unit;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 320px));
  grid-gap: $spacing-unit;
  justify-content: center;
  margin: $spacing-unit;
}
.video-card {
  background-color: white;
  border-radius: 32px;
  padding: 1.5em;
  h2 {
    font-size: $video-title;
    font-weight: bold;
    font-family: $base-font-family;
    line-height: 140%;
    margin-top: 8px;
    margin-bottom: 4px;
  }
  .video-meta {
    font-size: 16px;
    color: $grey-color;
    margin-bottom: -12px;
  }
  p, ul, li {
    font-size: $video-text;
  }
  p {
    margin-bottom: $spacing-unit / 2;
  }
  ul {
    margin-left: 0;
  }
  li {
    list-style: none;
    margin-left: 0;
    &::before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      background-image: url(/assets/img/ialja-mark.svg);
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 6px;
      margin-top: 12px;
    }
  }
  .read-more-link {
    font-size: 20px;
  }
}
.video-thumbnail {
  text-align: center;
  border: 3px solid $url-color;
  border-radius: 16px;
  background-color: black;
  overflow:hidden;
  img {
    &.horizontal {
      max-width: 100%;
      max-height: 200px;
    }
    &.vertical {
      max-width: 100%;
      max-height: 200px;
    }
  }
}
/* Container for single video page */
.video-page {
  max-width: 400px;
  margin: 0 auto;
}

@include media-query($on-laptop) {
  .videos {
    padding: 0 ($spacing-unit / 2);
  }
  .featured {
    padding: 60px ($spacing-unit / 2);
  }
  .video-container {
    margin: 0;
  }
}


/**
 * Other pages
 */
.podcast-thumbnail {
  float: left;
  width: 150px;
  height: 150px;
  border-radius: 16px;
  margin-top: 4px;
  margin-right: 16px;
  border: 3px solid $url-color;
}
.podcast-list {
  margin-left: 0;
  list-style: none;
  li {
    margin-bottom: 30px;
  }
}
.podcast-meta {
  margin-top: 12px;
  font-size: 0.9em;
  color: $grey-color;
  &::before {
    content: "";
    display: inline-block;
    width: 14px;
    height: 14px;
    background-image: url(/assets/img/external.svg);
    background-repeat: no-repeat;
    background-size: contain;
    padding-right: 8px;
  }
}
.connect-list {
  background-color: white;
  border-radius: 16px;
  list-style: none;
  display: inline-block;
  padding: ($spacing-unit / 2) $spacing-unit;
  text-align: center;
  width: 100%;
  margin: 0 0 $spacing-unit 0;
  box-sizing: border-box;
  li {
    display: inline-block;
    line-height: 42px;
    &:not(:last-child) {
      padding-right: 1em;
    }
  }
}

.kofi-element {
  max-width: 250px;
  box-sizing: border-box;
}