@charset "utf-8";

// Define defaults for each variable.

$head-font-family: 'Cherry Swash', serif;
$head-font-weight: 700 !default;
$base-font-family: 'Palanquin', sans-serif;
$base-font-size:   24px !default;
$palm-font-size:   20px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.618 !default;
$video-title:      22px !default;
$video-text:       18px !default;

$spacing-unit:     30px !default;

$text-color:       #333 !default;
$brand-color:      #33BD4C !default;
$accent-color:     #BD33A4 !default;
$highlight-color:  #FFCF00 !default;
$hover-color:      #FFA500 !default;
$url-color:        #008a00 !default;
$background-color: rgba($accent-color, 0.05) !default;
$background-highlight: #FFF8D8 !default;


$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$border-radius:    2px !default;
$box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
$table-text-align: left !default;

// Width of the content area
$content-width:    800px !default;
// Width of base layout
$layout-width:    1600px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/syntax-highlighting"
;
