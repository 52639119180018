
/* HEADINGS FONT */
/* cherry-swash-regular - latin_latin-ext */
@font-face {
  font-display: fallback; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Cherry Swash';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/cherry-swash-v18-latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/cherry-swash-v18-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../assets/fonts/cherry-swash-v18-latin_latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('../assets/fonts/cherry-swash-v18-latin_latin-ext-regular.woff') format('woff'), /* Modern Browsers */
        url('../assets/fonts/cherry-swash-v18-latin_latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../assets/fonts/cherry-swash-v18-latin_latin-ext-regular.svg#CherrySwash') format('svg'); /* Legacy iOS */
}
/* cherry-swash-700 - latin_latin-ext */
@font-face {
font-display: fallback; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
font-family: 'Cherry Swash';
font-style: normal;
font-weight: 700;
src: url('../assets/fonts/cherry-swash-v18-latin_latin-ext-700.eot'); /* IE9 Compat Modes */
src: url('../assets/fonts/cherry-swash-v18-latin_latin-ext-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../assets/fonts/cherry-swash-v18-latin_latin-ext-700.woff2') format('woff2'), /* Super Modern Browsers */
        url('../assets/fonts/cherry-swash-v18-latin_latin-ext-700.woff') format('woff'), /* Modern Browsers */
        url('../assets/fonts/cherry-swash-v18-latin_latin-ext-700.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../assets/fonts/cherry-swash-v18-latin_latin-ext-700.svg#CherrySwash') format('svg'); /* Legacy iOS */
}
/* BODY TEXT FONT */
/* palanquin-regular - latin_latin-ext */
@font-face {
  font-display: fallback; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Palanquin';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/palanquin-v13-latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/palanquin-v13-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../assets/fonts/palanquin-v13-latin_latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('../assets/fonts/palanquin-v13-latin_latin-ext-regular.woff') format('woff'), /* Modern Browsers */
        url('../assets/fonts/palanquin-v13-latin_latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../assets/fonts/palanquin-v13-latin_latin-ext-regular.svg#Palanquin') format('svg'); /* Legacy iOS */
}
/* palanquin-700 - latin_latin-ext */
@font-face {
  font-display: fallback; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Palanquin';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/palanquin-v13-latin_latin-ext-700.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/palanquin-v13-latin_latin-ext-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../assets/fonts/palanquin-v13-latin_latin-ext-700.woff2') format('woff2'), /* Super Modern Browsers */
        url('../assets/fonts/palanquin-v13-latin_latin-ext-700.woff') format('woff'), /* Modern Browsers */
        url('../assets/fonts/palanquin-v13-latin_latin-ext-700.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../assets/fonts/palanquin-v13-latin_latin-ext-700.svg#Palanquin') format('svg'); /* Legacy iOS */
}
/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
}

/* Scrollbar color */
html {
  scrollbar-color: $accent-color rgba($accent-color, 0.2);
  scrollbar-width: thin;
}

/* WebKit and Chromiums */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: rgba($accent-color, 0.2);
}

::-webkit-scrollbar-thumb {
  background: $accent-color;
  border-radius: 8px;
}



/**
 * Basic styling
 */
body {
  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
  color: $text-color;
  background-color: $background-color;
  accent-color: $accent-color;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
     -moz-font-feature-settings: "kern" 1;
       -o-font-feature-settings: "kern" 1;
          font-feature-settings: "kern" 1;
  font-kerning: normal;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

@include media-query($on-palm) {
  body {
    font-size: $palm-font-size;
  }
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
  margin-bottom: $spacing-unit / 2;
}

p {
  margin-bottom: $spacing-unit;
}

::selection {
  color: white;
  background-color: $accent-color;
}


/**
 * `main` element
 */
main {
  display: block; /* Default value of `display` of `main` element is 'inline' in IE 11. */
}



/**
 * Images
 */
img {
  max-width: 100%;
  vertical-align: middle;
}



/**
 * Figures
 */
figure {
  text-align: center;
 }
figure > img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
figcaption {
  font-size: $small-font-size;
  color: $grey-color;
  font-style: italic;
  padding-bottom: 1em;
}



/**
 * Lists
 */
ul, ol {
  margin-left: $spacing-unit;
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}



/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
  font-family: $head-font-family;
  font-weight: $base-font-weight;
  text-wrap: balance;
  line-height: normal;
  a {
    text-decoration: none;
  }
}
h1 {
  font-weight: $head-font-weight;
}



/**
 * Links
 */
a {
  color: $url-color;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 8px;
  -webkit-transition: color .3s ease;
  transition: color .3s ease;

  &:visited {
    color: darken($url-color, 5%);
  }

  &:hover {
    color: $accent-color;
  }

}


/**
 * Blockquotes
 */
blockquote {
  color: $grey-color;
  border-left: 4px solid $highlight-color;
  padding-left: $spacing-unit / 2;
  font-size: 0.9em;
  letter-spacing: -1px;
  font-style: italic;
  a,
  a:visited {
    color: $grey-color;
  }
}


/**
 * Code formatting
 */
pre,
code {
  @include relative-font-size(0.9375);
  border: 1px solid $grey-color-light;
  border-radius: 3px;
  background-color: #eef;
}

code {
  padding: 1px 5px;
}

pre {
  padding: 8px 12px;
  overflow-x: auto;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}



/**
 * Wrapper
 */
.wrapper {
  max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
  max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
  margin-right: auto;
  margin-left: auto;
  padding-right: $spacing-unit;
  padding-left: $spacing-unit;
  @extend %clearfix;

  @include media-query($on-laptop) {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
    max-width:         calc(#{$content-width} - (#{$spacing-unit}));
    padding-right: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
  }
}



/**
 * Clearfix
 */
%clearfix:after {
  content: "";
  display: table;
  clear: both;
}



/**
 * Icons
 */

.svg-icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    fill: #{$url-color};
    padding-right: 0.5em;
    scale: 1.2;
}

.social-media-list {
  li + li {
    padding-top: 5px;
  }
}


/**
 * Tables
 */
table {
  margin-bottom: $spacing-unit;
  width: 100%;
  text-align: $table-text-align;
  color: lighten($text-color, 18%);
  border-collapse: collapse;
  border: 1px solid $grey-color-light;
  tr {
    &:nth-child(even) {
      background-color: lighten($grey-color-light, 6%);
    }
  }
  th, td {
    padding: ($spacing-unit / 3) ($spacing-unit / 2);
  }
  th {
    background-color: lighten($grey-color-light, 3%);
    border: 1px solid darken($grey-color-light, 4%);
    border-bottom-color: darken($grey-color-light, 12%);
  }
  td {
    border: 1px solid $grey-color-light;
  }
}
